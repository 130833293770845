import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Search as SearchIcon } from '@mui/icons-material'
import ClearIcon from '@mui/icons-material/Clear'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { updateSearchTermWithType } from '../../../store/search/searchSlice'
import {
  placeholderTextBySearchType,
  searchTypeAddonsForAdmins,
  searchTypeListForUsers,
} from '../../../constants/search'
import { useSearchContext } from '../SearchContext'
import { BetaAnalytics } from '../../../../analytics/betaSearch'
import { isStringContainsQuotes } from '../../../utils/utils'

const useStyles = makeStyles({
  colorSwitchBase: {
    color: '#188295',
    '&.Mui-checked ': {
      color: '#188295',
    },
  },
  exactSearchGrid: {
    paddingLeft: '10px',
    display: 'flex',
    alignItems: 'center'
  },
  textGrid: {
    display: 'flex',
    padding: '20px 0px',
  },
  inputLabelColor: {
    cursor: 'pointer',
    height: '44px',
    color: 'black',
    borderRadius: '4px 0 0 4px',
  },
  searchInput: {
    height: '44px',
    width: '50vw',
    borderRadius: '0 4px 4px 0',
  },
  clearIconClass: {
    paddingRight: '10px',
    fontSize: '20px',
    cursor: 'pointer',
  },
  searchIcon: {
    borderLeft: '1px solid #C4C4C4',
    padding: '10px 0px 10px 10px',
    cursor: 'pointer',
    color: '#178295'
  },
  noLeftBorder: {
    borderRight: 'none !important'
  }
})

function SearchInputBox() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { trackAnalyticsData = () => { } } = useSearchContext()
  const {
    searchTerm = '',
    searchType = '',
    isAdmin = false,
    isLibrarian = false,
  } = useSelector((state) => ({
    searchTerm: state?.newSearch?.searchTerm || '',
    searchType: state?.newSearch?.searchType || '',
    isAdmin: state?.auth?.isAdGroupSuperAdmin || '',
    isLibrarian: state?.auth?.isLibrarian || '',
  }))
  const [textSearchValue, setTextSearchValue] = useState(searchTerm)
  const [searchTypeValue, setSearchTypeValue] = useState(searchType)

  const onSearchClick = () => {
    dispatch(
      updateSearchTermWithType({
        searchTerm: textSearchValue,
        searchType: searchTypeValue,
        isExactMatch: isStringContainsQuotes(textSearchValue),
      }),
    )
    trackAnalyticsData({
      eventType: BetaAnalytics.event.ADD_FILTER,
      eventName: BetaAnalytics.event.SEARCH_TEXT,
      eventData: { searchTerm: textSearchValue, searchType: searchTypeValue },
    })
    const navigationUrl = textSearchValue ? `?type=${searchTypeValue}&term=${textSearchValue}` : ''
    navigate(navigationUrl)
  }

  useEffect(() => {
    setTextSearchValue(searchTerm)
  }, [searchTerm])

  useEffect(() => {
    setSearchTypeValue(searchType)
  }, [searchType])

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSearchClick()
    }
  }

  const handleSearchTypeChange = (searchType = '') => {
    setSearchTypeValue(searchType)
  }

  const searchTypeList =
    isAdmin || isLibrarian ? searchTypeListForUsers.concat(searchTypeAddonsForAdmins) : searchTypeListForUsers

  return (
    <Grid container className={classes.searchGrid}>
      <Grid item xs={8} md={12} lg={12} className={classes.textGrid}>
        <FormControl size="small" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <InputLabel id='searchcriteria-label' className={classes.inputLabelColor}>Search Criteria</InputLabel>
          <Select
            labelId="searchcriteria-label"
            id="searchcriteria-label"
            data-cy={'selectType'}
            input={<OutlinedInput label="Search Criteria" classes={{ root: classes.inputLabelColor, notchedOutline: classes.noLeftBorder }}
            />}
            value={searchTypeValue}
            onChange={(event) =>
              handleSearchTypeChange(event.target.value)
            }
            sx={{
              width: '150px',
              fontSize: '16px',
              borderRight: 'none'
            }}
            autoWidth
            className={classes.searchTypeField}
          >
            {searchTypeList.map((searchTypeItem) => (
              <MenuItem value={searchTypeItem.value} data-cy={searchTypeItem.value}>
                {searchTypeItem.label}
              </MenuItem>
            ))}
          </Select>
          <TextField
            size="small"
            id="searchTerm"
            variant="outlined"
            data-cy={'searchTerm'}
            value={textSearchValue}
            onChange={(event) => {
              setTextSearchValue(event.target.value)
            }}
            placeholder={placeholderTextBySearchType[searchTypeValue]}
            autoFocus
            onKeyDown={(e) => handleKeyDown(e)}
            InputProps={{
              classes: { root: classes.searchInput },
              endAdornment: (
                <>
                  {
                    textSearchValue?.length ? <ClearIcon
                      style={{ cursor: 'pointer' }}
                      className={classes.clearIconClass}
                      onClick={() => setTextSearchValue('')}
                    /> : ''
                  }

                  <SearchIcon className={classes.searchIcon} onClick={() => onSearchClick()}
                  />
                </>

              )
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default SearchInputBox
