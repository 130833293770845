import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import { defaultFilters } from '../../betasearch/store/search/searchSlice'

const {
  genai: {
    promptsUrl = ''
  } = {},
  betaSearch: {
   url: betaSearchUrl = ''
  } = {},
  tenants: {
    tenantsUrl = ''
  } = {}
} = apiConfig

export const fetchPromptData = () => axios.get(promptsUrl)

export const fetchPromptDetailsById = (promptId = '') => axios.get(`${promptsUrl}/${promptId}/versions`)

export const createNewPrompt = (requestPayload = {}) => axios.post(promptsUrl, requestPayload)

export const updatePromptByPromptId = (requestPayload = {}) => axios.put(`${promptsUrl}`, requestPayload)

export const fetchSearchConfigWithDefaultFilters = () => axios.post(betaSearchUrl, {
  filters: defaultFilters,
  search_type: 'ALL',
  search_term: ''
})

export const fetchTenants = () => axios.get(`${tenantsUrl}`)

export const fetchCategoryList = () => axios.get(`${promptsUrl}/categories`)

export const togglePromptStatus = (groupingId = '') => axios.put(`${promptsUrl}/${groupingId}/toggle`)

